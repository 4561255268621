import React, {useCallback, useLayoutEffect, useRef} from 'react';
import classNames from 'classnames';
import {usePageContext} from '../../../componentsLib/Layout';
import {registerGlobalStyle} from '../../../theme';
import {HoopsPropTypes, scrollIntoView} from '../../../componentsLib';
import {Edit as EditIcon} from '@mui/icons-material';

export const SalesDocSections = {
  additionalCost: 'additionalCost',
  customerActionButtons: 'customerActionButtons',
  customerSection: 'customer',
  decoration: 'decoration',
  documentBlock: 'documentBlock',
  documentHeader: 'documentHeader',
  documentTerms: 'documentTerms',
  documentTotals: 'documentTotals',
  groupSection: 'group',
  groupHeaderSection: 'groupHeaderSection',
  product: 'product',
  productImage: 'productImage',
  imageGallery: 'imageGallery',
  sage: 'sage',
};

export const SalesDocSubSections = {
  addVendor: 'addVendor',
  addCustomer: 'addCustomer',
  editCustomer: 'editCustomer',
  addDecoration: 'addDecoration',
};

export const CartSubSections = {
  shopperDetails: 'shopperDetails',
  shopperUpdateFilesAndNotes: 'shopperUpdateFilesAndNotes',
  completeOrder: 'completeOrder'
};

registerGlobalStyle('.salesdoc-section', (theme) => ({
  borderRadius: theme.shape.borderRadius,
  position: 'relative',
  '.edit-icon': {
    opacity: 0,
    position: 'absolute',
    color: theme.colors.palette.blue,
    margin: theme.spacing(1),
    right: 0,
    zIndex: theme.zIndex.hovers
  },
  '.hide-edit-icon': {display: 'none'},
  '&:hover': {
    boxShadow: `inset 0 0 0 1px ${theme.colors.border.light}`,
    transition: theme.transitions.in.opacity,
    '.edit-icon': {opacity: 1, transition: theme.transitions.in.opacity},
  },
  '&.selected': {
    boxShadow: `inset 0 0 0 1px ${theme.colors.border.highlight}, 0 0 0 1px ${theme.colors.border.highlight}`,
    '.edit-icon': {opacity: 0, transition: theme.transitions.in.opacity},
  },
}));

export function SalesDocSection({className, section, groupId, itemId, variantId, hideEditIcon, children}) {
  const {selection, setSelection} = usePageContext();
  const sectionRef = useRef();

  const handleClick = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();

    setSelection({section, groupId, itemId, variantId});
    if (sectionRef.current) {
      scrollIntoView(sectionRef.current, true);
    }
  }, [variantId, groupId, itemId, section, setSelection]);

  const selected = selection && (selection.section === section)
    && selection.groupId === groupId
    && ((itemId != null && selection.itemId === itemId) || (variantId != null && selection.variantId === variantId) || (!itemId && !variantId));

  useLayoutEffect(() => {
    if (selected && sectionRef.current) {
      scrollIntoView(sectionRef.current, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classNames([className, 'salesdoc-section', selected && 'selected'])} ref={sectionRef} onClick={handleClick}>
      <EditIcon className={classNames(['edit-icon', hideEditIcon && 'hide-edit-icon'])} />
      {children}
    </div>
  );
}

SalesDocSection.propTypes = {
  section: HoopsPropTypes.string.isRequired,
  groupId: HoopsPropTypes.string,
  itemId: HoopsPropTypes.string,
  variantId: HoopsPropTypes.string,
  hideEditIcon: HoopsPropTypes.bool,
  children: HoopsPropTypes.children,
};
