import {asNumber} from '../../../utils';
import {getTaxTotal} from '../../../models';
import {SalesDocItem} from './SalesDoc';

export class SalesDocSummary {
  quantities = {};
  summary = {};
  subTotal;
  taxTotal;
  total;
  checkoutExtras = {
    subTotal: 0,
    taxTotal: 0,
    total: 0,
  };

  constructor(items) {
    if (items) {
      this.calculateQuantities(items);
      this.calculateSummary(items);
    }
  }

  getDocumentSummary() {
    return this.summary;
  }

  getGroupQuantity(groupId) {
    return this.quantities[groupId].quantity;
  }

  getGroupSummary(groupId) {
    return this.summary[groupId];
  }

  getItemSummary(itemId) {
    return this.summary[itemId];
  }

  getItemTotalIncTax(item) {
    return item.subTotal + getTaxTotal([item]);
  }

  getVariantQuantity(variantId) {
    return this.quantities[variantId];
  }

  getVariantRevenue(variantId) {
    return this.summary[variantId].totalRevenue;
  }

  getVariantSummary(variantId) {
    return {
      hasRollup: this.summary[variantId].rolledUpRevenue > 0 || this.summary[variantId].rolledUpCost > 0,
      totalCost: this.summary[variantId].totalCost,
      totalRevenue: this.summary[variantId].totalRevenue - this.summary[variantId].rolledUpRevenue,
      rolledUpCost: this.summary[variantId].totalCost + this.summary[variantId].rolledUpCost,
      rolledUpRevenue: this.summary[variantId].totalRevenue,
      quantity: this.summary[variantId].quantity,
    };
  }

  calculateQuantities(items) {
    // Initialize the quantities to 0.
    this.quantities = items
      .filter((item) => item.type === SalesDocItem.Type.VARIANT || !item.doc.groupHasProductVariants(item.groupId))
      .reduce((q, item) => ({
        ...q,
        [item.variantId]: 0,
        [item.productId]: 0,
        [item.groupId]: {
          ...q[item.groupId],
          quantity: 0,
          unlinked: 0,
          [item.productId]: 0,
        }
      }), {quantity: 0, unlinked: 0});

    // Now do the additions
    items
      .filter((item) => item.isProductVariant() || item.variantId != null)
      .forEach((item) => {
        const quantity = asNumber(item.quantity);
        if (item.isProductVariant()) {
          this.quantities.quantity += quantity;
          this.quantities[item.groupId].quantity += quantity;
          this.quantities[item.productId] += quantity;
          this.quantities[item.groupId][item.productId] += quantity;
          if (!item.product || item.product.decorations == null || item.product.decorations.length === 0) {
            this.quantities.unlinked += quantity;
            this.quantities[item.groupId].unlinked += quantity;
          }
        }
        if (item.variantId) {
          this.quantities[item.variantId] += quantity;
        }
      });
  }

  calculateSummary(items) {
    this.summary = {
      totalCost: 0,
      totalRevenue: 0,
      totalProductCost: 0,
      totalDecorationCost: 0,
      totalAdditionalCost: 0,
      quantity: this.quantities.quantity,
    };

    // Create a summary for the document overall, this will exclude checkout items
    items.filter((item) => !item.applyAtCheckout).forEach((item) => {
      // Document summary
      this.summary.totalCost += item.totalCost;
      if (!item.rollupSellPrice) {
        this.summary.totalRevenue += item.subTotal;
      }
      if (item.isProductVariant()) {
        this.summary.totalProductCost += item.totalCost;
      } else if (item.isDecoration() || item.isDecorationSetupCost()) {
        this.summary.totalDecorationCost += item.totalCost;
      } else {
        this.summary.totalAdditionalCost += item.totalCost;
      }

      // Group summary
      if (!this.summary[item.groupId]) {
        this.summary[item.groupId] = {
          totalCost: 0,
          totalRevenue: 0,
          quantity: this.quantities[item.groupId].quantity,
        };
      }
      this.summary[item.groupId].totalCost += item.totalCost;
      if (!item.rollupSellPrice) {
        this.summary[item.groupId].totalRevenue += item.subTotal;
      }
    });

    // Now create summaries for the variants and individual items, including for checkout items
    items.forEach((item) => {
      // Summary for the variants or individual items
      const variantOrItemId = item.variantId ?? item.itemId;
      if (!this.summary[variantOrItemId]) {
        this.summary[variantOrItemId] = {
          totalCost: 0,
          totalRevenue: 0,
          rolledUpCost: 0,
          rolledUpRevenue: 0,
          quantity: 0,
        };
      }
      this.summary[variantOrItemId].totalCost += item.totalCost;
      this.summary[variantOrItemId].totalRevenue += item.subTotal;
      this.summary[variantOrItemId].rolledUpCost += (item.rolledUpCost ?? 0) * item.quantity;
      this.summary[variantOrItemId].rolledUpRevenue += (item.rolledUpUnitPrice ?? 0) * item.quantity;
      this.summary[variantOrItemId].quantity += item.quantity;

      if (item.applyAtCheckout) {
        this.checkoutExtras.subTotal += item.subTotal;
      }
    });

    this.checkoutExtras.taxTotal = getTaxTotal(items.filter((item) => item.tax && item.applyAtCheckout));
    this.checkoutExtras.total = this.checkoutExtras.taxTotal + this.checkoutExtras.subTotal;

    this.subTotal = this.summary.totalRevenue;
    this.taxTotal = getTaxTotal(items.filter((item) => item.tax && !item.rollupSellPrice && !item.applyAtCheckout));
    this.total = this.taxTotal + this.subTotal;
  }
}
